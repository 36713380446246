@font-face {
    font-family: 'CabinetGrotesk';
    src: url('./assets/Fonts/CabinetGrotesk-Variable.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    src: url('./assets/Fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
  }

  html {
    scroll-behavior: smooth;
  }  

  body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    background-color: #F7F4F0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  